<template>
  <v-container id="Land-proifle-tables" fluid tag="section">
    <v-dialog v-model="transcript_show" max-width="80%" @click:outside="close">
      <v-card>
        <json-viewer
          :value="transcript_json"
          :expand-depth="5"
          copyable
        ></json-viewer>
      </v-card>
    </v-dialog>
    <ProfileEditDialog
      :profile_edit_show="profile_edit_show"
      :lbkey="edit_lbkey"
      :mongo_url="mongo_url"
      :mongo_owners_url="mongo_owners_url"
      v-on:onClose="onClose"
    >
    </ProfileEditDialog>
    <ProfileListEditDialog
      :profile_list_edit_show="profile_list_edit_show"
      :lbkey="edit_lbkey"
      :base_url="base_url"
      v-on:onClose="onClose"
    ></ProfileListEditDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      title="Land Profile"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :loading="loading"
        dense
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:header>
          <CDRSelectFilter
            v-on:onCDRFilterChange="onCDRFilterChange"
            ref="childRef"
          >
            <td>
              <v-text-field
                v-model="filterItem.lbkey"
                outlined
                dense
                clearable
                @change="callFilterApply"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="filterItem.owners_num"
                outlined
                dense
                clearable
                @change="callFilterApply"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="filterItem.creditors_num"
                outlined
                dense
                clearable
                @change="callFilterApply"
              ></v-text-field>
            </td>
            <td>
              <v-checkbox
                v-model="filterItem.has_private"
                color="red"
              ></v-checkbox>
            </td>

            <td>
              <v-text-field
                v-model="filterItem.c_add_private"
                outlined
                dense
                @change="callFilterApply"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="filterItem.c_add_p_num"
                outlined
                dense
                @change="callFilterApply"
              ></v-text-field>
            </td>
            <td>
              <v-select
                label="has tp"
                :items="has_tp"
                v-model="filterItem.has_tp"
                outlined
                dense
              ></v-select>
            </td>
            <td>
              <v-btn color="primary" text @click="callFilterApply">
                apply
              </v-btn>
            </td>
            <td>
              <v-btn color="primary" text @click="reset"> reset </v-btn>
            </td>
          </CDRSelectFilter>
        </template>
        <template v-slot:item.cdr_name="{ item }">
          {{ getCDRName(item) }}</template
        >

        <template v-slot:item.district="{ item }"
          >{{ item.district.name }}({{ item.district.code }})
        </template>
        <template v-slot:item.region="{ item }"
          >{{ item.region.name }}({{ item.region.code }})
        </template>

        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="exportInfo(item)"
                >mdi-application-export</v-icon
              >
            </template>
            <span>{{ $t("export") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="getTranscriptJson(item)"
                >mdi-eye-outline</v-icon
              >
            </template>
            <span>{{ $t("view tp") }}</span>
          </v-tooltip>
        </template>
        <!-- edit action -->
        <template v-slot:item.edit_actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="editProfile(item)"
                >mdi-application-export</v-icon
              >
            </template>
            <span>{{ $t("edit-tp") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="editListProfile(item)"
                >mdi-playlist-edit</v-icon
              >
            </template>
            <span>{{ $t("edit-list") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <v-row>
      <v-col cols="10">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="20"
        ></v-pagination>
      </v-col>
      <v-col cols="2">
        <v-row>
          <v-col cols="2" class="jump-page px-0 px-0">
            <span>跳轉</span>
          </v-col>
          <v-col cols="4" class="px-0 px-0">
            <v-text-field v-model="jump"></v-text-field>
          </v-col>
          <v-col cols="2" class="jump-page px-0 px-0"><span>頁</span></v-col>
          <v-col cols="2" class="jump-page px-0 px-0">
            <v-btn small @click="jumpTo()">GO</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import JsonViewer from "vue-json-viewer";
import CDRSelectFilter from "../filters/CDRSelectFilter";
import ProfileEditDialog from "../dialogs/ProfileEditDialog";
import ProfileListEditDialog from "../dialogs/ProfileListEditDialog";
import { mapState, mapMutations, mapActions } from "vuex";
import { city_info } from "@/definition.js";
export default {
  data: (vm) => ({
    jump: 1,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialog_form: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    default_item: {
      city__code: "H",
      district__code: null,
      region__code: null,
      lbkey: null,
      owners_num: null,
      creditors_num: null,
      c_add_private: null,
      c_add_p_num: null,
      create_time: {},
    },
    filterItem: {
      city__code: "H",
      district__code: null,
      region__code: null,
      lbkey: null,
      owners_num: null,
      creditors_num: null,
      c_add_private: null,
      c_add_p_num: null,
      create_time: {},
      has_tp: null,
    },
    profile_edit_show: false,
    profile_list_edit_show: false,
    edit_lbkey: null,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("city_name"),
        value: "city.name",
        width: "7%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("district_name"),
        // value: "district.name",
        value: "district",
        width: "7%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region_name"),
        value: "region",
        width: "10%",
      },

      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
        width: "20%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owners_num"),
        value: "owners_num",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("creditors_num"),
        value: "creditors_num",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("creditors_add_private_num"),
        value: "creditors_add_private_num",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("creditors_add_private"),
        value: "creditors_add_private",
        width: "5%",
      },

      {
        sortable: false,
        text: vm.$i18n.t("transcript"),
        value: "transcript",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("edit_actions"),
        value: "edit_actions",
        width: "10%",
      },
    ],
    city_info: city_info,
    district_info: [],
    region_info: [],
    interval: [
      {
        text: vm.$i18n.t("latest"),
        value: 0,
      },
      {
        text: vm.$i18n.t("threeDays"),
        value: 3,
      },
      {
        text: vm.$i18n.t("Week"),
        value: 7,
      },
    ],
    has_tp: [
      {
        text: vm.$i18n.t("all"),
        value: 0,
      },
      {
        text: vm.$i18n.t("no"),
        value: 1,
      },
      {
        text: vm.$i18n.t("yes"),
        value: 2,
      },
    ],
    transcript_show: false,
    transcript_json: null,
  }),
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  components: {
    CDRSelectFilter,
    JsonViewer,
    ProfileEditDialog,
    ProfileListEditDialog,
  },
  computed: {
    ...mapState(["city", "district", "region", "token"]),
  },
  methods: {
    ...mapMutations(["setCity", "setRegion"]),
    getCDRName(item) {
      return item.city.name + "/" + item.district.name + "/" + item.region.name;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + this.base_url;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      for (const [key, value] of Object.entries(this.filterItem)) {
        if (!this.$common_util.isEmpty(value)) {
          config.params[key] = value;
        }
      }
      // get some fileds
      // config.params["transcript__isnull"] = false;

      if (this.filterItem.has_private == true) {
        config.params["creditors_add_private_num__gt"] = 0;
      }
      // config.params["query"] = "{ id, city, district, region, lbkey }";
      // if (this.filterItem.has_tp == 1) {
      //   config.params["transcript__isnull"] = 1;
      // } else if (this.filterItem.has_tp == 2) {
      //   config.params["transcript__isnull"] = 0;
      // }

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    postApi() {},
    getList(data) {
      return data;
    },
    getRegNo(data) {
      if (data === null || data === "") {
        return data;
      }
      let parseObj = JSON.parse(data.replace(/'/g, '"'));
      console.log(parseObj);
      let result = "";
      for (let i = 0; i < parseObj.length; i++) {
        result += parseObj[i] + ";";
      }

      return result;
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
      const currentObj = this;
    },
    onCDRFilterChange(item) {
      this.filterItem = this._.cloneDeep(item);
      // console.log("this filter",this.filterItem)
      this.getApi();
    },
    getTranscriptJson(item, set_result_func = this.setTranscriptResult) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.mongo_url + item.transcript + "/";
      console.log("url " + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setTranscriptResult(data) {
      this.transcript_json = data;
      this.transcript_show = true;
    },
    close() {
      this.transcript_show = false;
    },
    callFilterApply() {
      this.$refs.childRef.onChangeFilter(this.filterItem);
    },
    exportInfo(item) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}${this.export_url}${item.lbkey}/exportPDF/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        responseType: "blob",
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let filename = `${item.lbkey}.pdf`;
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    forceFileDownload(response, filename) {
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.download = filename;
      aTag.click();

      // 清掉暫存
      aTag.href = "";
      URL.revokeObjectURL(url);
    },
    reset() {
      this.filterItem = this._.cloneDeep(this.default_item);
      this.getApi();
    },
    onClose() {
      this.profile_edit_show = false;
      this.profile_list_edit_show = false;
      this.edit_lbkey = null;
    },
    editProfile(item) {
      this.profile_edit_show = true;
      this.edit_lbkey = item.lbkey;
    },
    editListProfile(item) {
      this.profile_list_edit_show = true;
      this.edit_lbkey = item.lbkey;
    },
    jumpTo() {
      this.page = parseInt(this.jump);
    },
  },
};
</script>
<style scoped>
.jump-page {
  /* margin-left: 10px;
  margin-right: 10px; */

  padding-top: 32px !important;
}
</style>
