<template>
  <v-dialog
    v-model="profile_list_edit_show"
    max-width="900px"
    @click:outside="onClose"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("list-edit") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="8"> </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                single-line
                hide-details
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :loading="loading"
            :search="search"
            dense
          >
            <template v-slot:item.name="{ item }">
              <v-edit-dialog large @save="changed=true">
                {{ item.name }}
                <template v-slot:input>
                  <v-row align="baseline">
                    <v-text-field
                      v-model="item.name"
                      :label="$t('name')"
                      large
                    ></v-text-field>
                  </v-row>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="onSave">
          {{ $t("save") }}
        </v-btn>
        <v-btn color="secondary" dark @click="onClose">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    profile_list_edit_show: false,
    lbkey: null,
    base_url: null,
    mongo_url: null,
    mongo_owners_url: null,
  },
  data: (vm) => ({
    search: "",
    changed: false,
    options: {},
    items: [],
    loading: false,
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("regno"),
        value: "regno",
      },
      {
        sortable: false,
        text: vm.$i18n.t("name"),
        value: "name",
      },
    ],
  }),
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    lbkey: {
      handler() {
        if (this.profile_list_edit_show && this.lbkey != null) {
          this.getApi();
        }
      },
    },
  },
  methods: {
    getApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + this.base_url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          lbkey: this.lbkey,
        },
      };
      config.params["query"] = "{ id, owners_json }";
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.loading = false;
          let result = response.data;

          if (result.results.length > 0) {
            let owners_json = result.results[0]["owners_json"];
            currentObj.items = [];
            for (let key in owners_json) {
              currentObj.items.push(owners_json[key]);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onSave() {
      if (this.changed) {
        this.changed = false;
        this.patch();
      }
      this.onClose();
    },
    onClose() {
      this.items = [];
      this.$emit("onClose");
    },
    patch() {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.base_url + this.lbkey + "/";

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      let jsonData = {
        owners_json: this.items,
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          // console.log(response.data);
          console.log("patch done")
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>