<template>
  <v-dialog
    v-model="profile_edit_show"
    max-width="600px"
    @click:outside="onClose"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("TP edit") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="4">
              <v-autocomplete
                :search-input.sync="searchInput"
                :label="$t('o_data')"
                :items="o_data_items"
                v-model="regno"
                @change="onChange"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="o_token"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" dark @click="findOwner()">
                {{ $t("find") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="8">
              <v-text-field v-model="o_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="8">
              <v-text-field v-model="o_addr"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" dark @click="updateOwnerName()">
                {{ $t("update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    profile_edit_show: false,
    lbkey: null,
    mongo_url: null,
    mongo_owners_url: null,
  },
  data: (vm) => ({
    o_data_items: [],
    o_data_value: [],
    searchInput: "",
    o_token: null,
    regno: null,
    o_name: null,
    o_addr: null,
  }),
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    lbkey: {
      handler() {
        if (this.profile_edit_show && this.lbkey != null) {
          this.getApi();
        }
      },
    },
  },
  methods: {
    getApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + this.mongo_url + "id_only/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          lbkey: this.lbkey,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          // currentObj.setResult(response.data);
          currentObj.transcript = response.data;
          let o_data = response.data["o_data"];

          for (let item in o_data) {
            currentObj.o_data_items.push(item);
            currentObj.o_data_value.push(o_data[item]);
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onClose() {
      this.reset();
      this.$emit("onClose");
    },
    onChange() {
      console.log("on chanage");
      this.o_token = this.transcript["o_data"][this.regno];
      this.findOwner()
    },
    findOwner() {
      if (this.o_token != null) {
        // get the owner by tp
        console.log("get the owner by " + this.o_token);
        const currentObj = this;
        const url =
          process.env.VUE_APP_SERVER_URL +
          this.mongo_owners_url +
          this.o_token +
          "/";
        console.log("url " + url);

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + this.token,
          },
        };
        this.axios
          .get(url, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.loading = false;
            currentObj.o_name = response.data["所有權人姓名"];
            currentObj.o_addr = response.data["住址"];
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }
    },
    reset() {
      this.o_data_items = [];
      this.o_data_value = [];
      this.searchInput = "";
      this.o_token = null;
      this.regno = null;
      this.o_name = null;
      this.o_addr = null;
    },
    updateOwnerName() {
      console.log("update the regno " + this.regno + " to :name" + this.o_name);
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        this.mongo_owners_url +
        this.o_token +
        "/";
      let jsonData = {
        所有權人姓名: this.o_name,
        住址: this.o_addr,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>